<template>
  <div>
    <el-tabs v-model="activeName">
      <el-tab-pane label="平台返利账户" name="VIRTUAL_CREDIT">
        <CommonRebate v-if="activeName === 'VIRTUAL_CREDIT'"></CommonRebate>
      </el-tab-pane>
      <el-tab-pane label="年框返利账户" name="YEARLY_VIRTUAL_CREDIT">
        <FormList account-type="rebate-list"></FormList>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import FormList from './components/FormList';
import CommonRebate from './components/CommonRebate';
export default {
  name: '',
  components: { FormList, CommonRebate },
  data() {
    return {
      activeName: 'VIRTUAL_CREDIT',
    };
  },
  mounted() {},
  computed: {},
  watch: {},
  methods: {}
};
</script>

<style  scoped>
</style>