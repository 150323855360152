<template>
  <div class="app-container">
    <RebateHeader accountType="VIRTUAL_CREDIT"/>
    <el-table
      :data="list"
      element-loading-text="加载中"
      fit
      highlight-current-row
      ref="multipleTable"
      v-loading.body="listLoading"
    >
      <el-table-column align="center" label="变动类型" prop="channelName"></el-table-column>
      <el-table-column align="center" label="出入账类型">
        <template slot-scope="{ row }">
          <span>
            {{ row.changeAmount > 0 ? '入账' : '出账' }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="账户名称"
        prop="distributorPartyRelationName"
      >
        <template slot-scope="{ row }">
          <span v-if="row.accountType === 'VIRTUAL_CREDIT'"> {{row.accountTypeName + (row.subtypeValueName || '')}} </span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="变动金额">
        <template slot-scope="{ row }">
          <span>
            {{ row.changeAmount > 0 ? '+' + row.changeAmount : row.changeAmount }}
          </span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="变动原因" prop="changeNotes"></el-table-column>
      <el-table-column align="center" label="变动时间">
        <template slot-scope="scope">{{ scope.row.createDate | parseDefaultTime() }}</template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        :current-page="formInline.pageNo"
        :disabled="listLoading"
        :page-size="formInline.pageSize"
        :page-sizes="[10, 20, 30, 40]"
        :total="total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        background
        layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { listAccountDetailPage, listOwnCompanyInfo } from '@/api/shop';
import pick from 'lodash/pick';
import cloneDeep from 'lodash/cloneDeep';
import RebateHeader from './RebateHeader';
export default {
  components: { RebateHeader },
  data() {
    return {
      total: 0,
      list: [],
      listLoading: false,
      data: {},
      ownCompanyInfoList: [],
      title: '',
      formInline: {
        data: {
          ownCompanyId: '',
          incomeOrOutType: '',
        },
        pageNo: 1,
        pageSize: 10,
      },
    };
  },
  props: {
    accountType: String,
  },
  mounted() {
      // 返利
    this.type = 'VIRTUAL_CREDIT';
    this.onSearch();
  },
  methods: {
    onReset() {
      this.formInline = cloneDeep({
        data: {
          ownCompanyId: '',
          incomeOrOutType: '',
        },
        pageNo: 1,
        pageSize: 10,
      });
      this.onSearch();
    },
    onSearch() {
      this.fetchData();
    },
    fetchData() {
      this.listLoading = true;
      const listQuery = pick(this.formInline, ['pageNo', 'pageSize', 'data']);
        listQuery.data.accountType = this.type;
      listAccountDetailPage(listQuery)
        .then(response => {
          const { list = [], total = 0 } = response.data || {};
          this.list = list;
          this.total = total;
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
    // 获取经营主体
    fetchOwnCompanyInfo() {
      listOwnCompanyInfo().then(response => {
        const data = response.data || {};
        this.ownCompanyInfoList = data.map(item => {
          return {
            value: item.ownCompanyId,
            label: item.ownCompanyName,
          };
        });
      });
    },
    handleSizeChange(val) {
      this.formInline.pageSize = val;
      this.formInline.pageNo = 1;
      this.fetchData();
    },
    handleCurrentChange(val) {
      this.formInline.pageNo = val;
      this.fetchData();
    },
    filtration(channel) {
      const addset = new Set(['SYS_ADD', 'RECHARGE_ADD', 'PURCHASE_REFUND_ADD']);
      if (addset.has(channel)) {
        return '+';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>